import { createRouter, createWebHashHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import { useAlertStore, useAuthStore, useSocketStore } from '@/stores'
import { hasAcl } from "@/config/acl.js";

const Login = () => import('@/views/Login.vue');
const UserRegistration = () => import('@/views/UserRegistration.vue');
const Not_Found = () => import('@/views/NotFound.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const Product = () => import('@/views/Product.vue');


const Product_List = () => import('@/views/product/ListProduct.vue');
const Product_View = () => import('@/views/product/ViewProduct.vue');
const Product_Tags = () => import('@/views/product/ProductTags.vue');
const Product_Attribute = () => import('@/views/product/ProductAttribute.vue');
const Product_Attribute_Option = () => import('@/views/product/ProductAttributeOption.vue');
const Product_Supplier = () => import('@/views/product/ProductSupplier.vue');
const Product_Units = () => import('@/views/product/ProductUnits.vue');
const Product_Batch = () => import('@/views/product/ProductBatch.vue');
const Product_Location = () => import('@/views/product/ProductLocation.vue');
const Create_Edit_Product = () => import('@/components/product/CreateEditProduct.vue');
const Product_Daily_Price = () => import('@/views/product/ProductDailyPrice.vue');
const Uploads = () => import('@/views/configuration/Uploads.vue');
const Trade_Type = () => import('@/views/product/TradeType.vue')
const Multiple_Selling_Price = () => import('@/views/product/MultipleSellingPrice.vue')

const Production = () => import('@/views/Production.vue');
const Packaging = () => import('@/views/production/ProductPackaging.vue');
const Manufacturing = () => import('@/views/production/ProductManufacturing.vue');

const Purchase = () => import('@/views/Purchase.vue');
const Purchase_Order_List = () => import('@/views/purchase/PurchaseOrder.vue');
const Purchase_Order_View = () => import('@/views/purchase/ViewPurchaseOrder.vue');
const Purchase_Order_Create_Edit = () => import('@/views/purchase/CreateEditPurchaseOrder.vue');
const Purchase_Bill_List = () => import('@/views/purchase/PurchaseBill.vue');
const Purchase_Bill_View = () => import('@/views/purchase/ViewPurchaseBill.vue');
const Purchase_Bill_Create_Edit = () => import('@/views/purchase/CreateEditPurchaseBill.vue');
const Good_Received_Note_List = () => import('@/views/purchase/GoodReceivedNote.vue');
const Good_Received_Note_View = () => import('@/views/purchase/ViewGoodReceivedNote.vue');
const Good_Received_Note_Create_Edit = () => import('@/views/purchase/CreateEditGoodsReceivedNote.vue');
const Debit_Note_List = () => import('@/views/purchase/DebitNote.vue');
const Debit_Note_View = () => import('@/views/purchase/ViewDebitNote.vue');
const Debit_Note_Create_Edit = () => import('@/views/purchase/CreateEditDebitNote.vue');


const Sales = () => import('@/views/Sale.vue');
const Sales_Order_List = () => import('@/views/sale/SalesOrder.vue');
const Sales_Order_View = () => import('@/views/sale/ViewSalesOrder.vue');
const Sales_Invoice_List = () => import('@/views/sale/SalesInvoice.vue');
const Sales_Invoice_View = () => import('@/views/sale/ViewSalesInvoice.vue');
const Credit_Note_List = () => import('@/views/sale/CreditNote.vue');
const Credit_Note_View = () => import('@/views/sale/ViewCreditNote.vue');
const Point_Of_Sales = () => import('@/views/sale/PointOfSale.vue');
const Credit_Note_Create_Edit = () => import('@/views/sale/CreateEditCreditNote.vue');
const Sales_Order_Create_Edit = () => import('@/views/sale/CreateEditSalesOrder.vue');
const Sales_Invoice_Create_Edit = () => import('@/views/sale/CreateEditSalesInvoice.vue');

const Delivery_Note_List = () => import('@/views/sale/DeliveryNote.vue');
const Delivery_Note_Create_Edit = () => import('@/views/sale/CreateEditDeliveryNote.vue');
const Delivery_Note_View = () => import('@/views/sale/ViewDeliveryNote.vue');
const Sales_Invoice_Conversion_Delivery_Note = () => import('@/views/sale/CreateEditConversionDeliveryNoteToSalesInvoice.vue')


const Account = () => import('@/views/Account.vue');
const Bank = () => import('@/views/account/Bank.vue');
const Voucher_List = () => import('@/views/account/Voucher.vue');
const Voucher_View = () => import('@/views/account/ViewVoucherDetails.vue');
const Stock_List = () => import('@/views/account/Stock.vue');
const Cash_Management_List = () => import('@/views/account/CashManagement.vue');
const Weighted_Average_Price_List = () => import('@/views/account/WeightedAveragePrice.vue');
const Stock_Transfer_List = () => import('@/views/account/StockTransfer.vue');
const Stock_Transfer_View = () => import('@/views/account/ViewStockTransfer.vue');
const Stock_Location_Transfer_List = () => import('@/views/account/StockLocationTransfer.vue');
const Stock_Location_Transfer_View = () => import('@/views/account/ViewStockLocationTransfer.vue');
const Other_Bill = () => import('@/views/account/OtherBill.vue');
const Other_Bill_View = () => import('@/views/account/ViewOtherBill.vue');
const Stock_Adjustment = () => import('@/views/account/StockAdjustment.vue');
const Stock_Adjustment_View = () => import('@/views/account/ViewStockAdjustment.vue');
const Special_Voucher_List = () => import('@/views/account/SpecialVoucher.vue');
const Fiscal_Year_Closing = () => import('@/views/account/FiscalYearClosing.vue');


const Unit = () => import('@/views/Unit.vue');
const Unit_List = () => import('@/views/unit/Unit.vue');
const Unit_Group_List = () => import('@/views/unit/UnitGroup.vue');

const Notification = () => import('@/views/Notification.vue');
const Notification_Message = () => import('@/views/notification/NotificationMessage.vue');
const Notification_Settings = () => import('@/views/notification/NotificationSettings.vue');
const Notification_User = () => import('@/views/notification/NotificationUser.vue');
const Socket_Session_User = () => import('@/views/notification/SocketSessionUser.vue');

const User = () => import('@/views/User.vue');
const User_List = () => import('@/views/user/User.vue');
const Organization_User_List = () => import('@/views/user/UserByOrganization.vue');
const User_Organization_List = () => import('@/views/user/UserOrganization.vue');
const User_Activity = () => import('@/views/user/UserActivity.vue');
const Switch_Organization = () => import('@/views/user/SwitchOrganization.vue')
const User_Device = () => import('@/views/user/UserDevice.vue')

const Role = () => import('@/views/Role.vue');
const Role_List = () => import('@/views/roles/Role.vue');
const Resource_List = () => import('@/views/roles/Resources.vue');
const Action_List = () => import('@/views/roles/Action.vue');
const Resource_Action_List = () => import('@/views/roles/ResourceAction.vue');

const Configuration = () => import('@/views/Configuration.vue');
const Account_Head_List = () => import('@/views/configuration/AccountHead.vue');
const Attribute_List = () => import('@/views/configuration/Attribute.vue');
const Attribute_Option_List = () => import('@/views/configuration/AttributeOption.vue');
const Brand_List = () => import('@/views/configuration/Brand.vue');
const Cash_Flow_Config_List = () => import('@/views/configuration/CashFlowConfig.vue');
const Category_List = () => import('@/views/configuration/Category.vue');
const Managed_Account_Head_List = () => import('@/views/configuration/ManagedAccountHead.vue');
const Manufacture_List = () => import('@/views/configuration/Manufacture.vue');
const Product_Type_List = () => import('@/views/configuration/ProductType.vue');
const Tags_List = () => import('@/views/configuration/Tags.vue');
const Voucher_Template_List = () => import('@/views/configuration/VoucherTemplate.vue');
const Cost_Center = () => import('@/views/configuration/CostCenter.vue');

const General = () => import('@/views/General.vue');
const Terms_Conditions = () => import('@/views/general/TermsConditions.vue');
const Base_Order_Number_List = () => import('@/views/general/BaseOrderNumber.vue');
const Organization_List = () => import('@/views/general/Organization.vue');
const Branch_List = () => import('@/views/general/Branch.vue');
const Branch_Global_settings_List = () => import('@/views/general/BranchGlobalSettings.vue');
const Customer_Category_List = () => import('@/views/general/CustomerCategory.vue');
const Customer_Loyalty_Point_List = () => import('@/views/general/CustomerLoyaltyPoint.vue');
const Forecast_Config_List = () => import('@/views/general/ForecastConfig.vue');
const Gift_Config_List = () => import('@/views/general/GiftConfig.vue');
const Global_Settings_List = () => import('@/views/general/GlobalSettings.vue');
const Location_List = () => import('@/views/general/Location.vue');
const Loyalty_Point_List = () => import('@/views/general/LoyaltyPoint.vue');
const Scheme_List = () => import('@/views/general/Scheme.vue');
const Overhead_Expenses = () => import('@/views/general/OverheadExpenses.vue');

const Reports = () => import('@/views/Reports.vue');
const Vat_Sales_Report = () => import('@/views/reports/VatSalesReport.vue');
const Vat_Purchase_Report = () => import('@/views/reports/VatPurchaseReport.vue');
const Sales_Book_Report = () => import('@/views/reports/SalesBookReport.vue');
const Sales_Register_Report = () => import('@/views/reports/SalesRegisterReport.vue');
const Purchase_Book_Report = () => import('@/views/reports/PurchaseBookReport.vue');
const Purchase_Register_Report = () => import('@/views/reports/PurchaseRegisterReport.vue');
const Credit_Note_Report = () => import('@/views/reports/CreditNoteReport.vue');
const Debit_Note_Report = () => import('@/views/reports/DebitNoteReport.vue');
const Stock_Report = () => import('@/views/reports/StockReport.vue');
const Opening_Stock_Report = () => import('@/views/reports/OpeningStockReport.vue');
const Balance_Sheet_Report = () => import('@/views/reports/BalanceSheetReport.vue');
const Trial_Balance_Report = () => import('@/views/reports/TrialBalanceReport.vue');
const Income_Statement_Report = () => import('@/views/reports/IncomeStatementReport.vue');
const Customer_Transaction_Report = () => import('@/views/reports/CustomerTransactionReport.vue');
const Supplier_Transaction_Report = () => import('@/views/reports/SupplierTransactionReport.vue');
const Supplier_Transaction_By_Id_Report = () => import('@/views/reports/transactionReport/CustomerTransactionReportBySupplierId.vue');
const Customer_Transaction_By_Id_Report = () => import('@/views/reports/transactionReport/CustomerTransactionReportByCustomerId.vue');
const Sub_Ledger_Report = () => import('@/views/reports/SubLedgerReport.vue');
const Stock_Summary_Report = () => import('@/views/reports/StockSummaryReport.vue');
const Stock_Summary_Report_By_Id = () => import('@/views/reports/StockSummaryReportById.vue');
const Customer_Balance_Confirmation_Report = () => import('@/views/reports/CustomerBalanceConfirmationReport.vue');
const Supplier_Balance_Confirmation_Report = () => import('@/views/reports/SupplierBalanceConfirmationReport.vue');



const View_Day_Book_Report = () => import('@/views/reports/ViewDayBookReport.vue');
const Product_Purchase_Transaction_Report = () => import('@/views/reports/dayBookReport/ProductPurchaseTransactionReport.vue');
const Product_Sales_Transaction_Report = () => import('@/views/reports/dayBookReport/ProductSalesTransactionReport.vue');
const Product_Stock_Report = () => import('@/views/reports/dayBookReport/ProductStockReport.vue');
const Cash_And_Bank_Report_Transaction_Report = () => import('@/views/reports/dayBookReport/CashAndBankTransactionReport.vue');
const Bank_Report_Transaction_Report = () => import('@/views/reports/dayBookReport/BankTransactionReport.vue');
const Cash_Report_Transaction_Report = () => import('@/views/reports/dayBookReport/CashTransactionReport.vue');
const View_Cash_and_Bank_Report = () => import('@/views/reports/ViewCashAndBankReport.vue');
const Safety_Stock_Report = () => import('@/views/reports/SafetyStockReport.vue');
const Expiry_Report = () => import('@/views/reports/ExpiryReport.vue');
const Reorder_Report = () => import('@/views/reports/ReorderReport.vue');
const Item_Wise_Sales_Order = () => import('@/views/reports/ItemWiseSalesOrderReport.vue');
const Item_Wise_Sales_Report = () => import('@/views/reports/ItemWiseSalesInvoiceReport.vue')
const Customer_Ageing_Report = () => import('@/views/reports/CustomerAgeingReport.vue')
const Supplier_Ageing_Report = () => import('@/views/reports/SupplierAgeingReport.vue')


const Supplier = () => import('@/views/purchase/Supplier.vue');
const Customer = () => import('@/views/sale/Customer.vue');

const Subscription = () => import('@/views/Subscription.vue')
const Software_Feature = () => import('@/views/subscription/SoftwareFeature.vue');

const Subscription_Feature = () => import('@/views/subscription/SubscriptionFeature.vue');
const Subscription_Package = () => import('@/views/subscription/SubscriptionPackage.vue');
const Subscription_Validity = () => import('@/views/subscription/SubscriptionValidity.vue');
const Organization_Subscription = () => import('@/views/subscription/OrganizationSubscription.vue');
const View_Organization_Subscription = () => import('@/views/subscription/ViewOrganizationSubscription.vue');
const View_Subscription_Package = () => import('@/views/subscription/ViewSubscriptionPackage.vue');

const Super_Admin_Dashboard = () => import('@/superAdmin/dashboard.vue');

export const router = createRouter(
    {
        history: createWebHashHistory(),
        routes:
            [
                {
                    path: '/Login',
                    name: 'Login',
                    component: Login,
                    meta: {
                        icon: 'mdi-view-dashboard',
                        breadcrumb: [
                            { name: 'Login' }
                        ],
                        allowAnonymous: true
                    }
                },
                {
                    path: '/userRegistration/:name/:userName',
                    name: 'User Registration',
                    component: UserRegistration,
                    meta: {
                        icon: 'mdi-view-dashboard',
                        breadcrumb: [
                            { name: 'User Registration' }
                        ],
                        allowAnonymous: true
                    }
                },
                {
                    path: '/:catchAll(.*)', //catch all 404 Not Found
                    name: 'NotFound',
                    component: Not_Found
                },
                {
                    path: '/pos',
                    // component: PosLayout,
                    children:
                        [
                            {
                                path: 'pointOfSale',
                                name: 'Point of Sale',
                                component: Point_Of_Sales,
                                meta: {
                                    icon: "fa fa-cart-arrow-down",
                                    breadcrumb: [
                                        { name: 'Point of Sale' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'POINT_OF_SALE',
                                    action: 'VIEW',
                                },
                            },
                            {
                                path: 'posDraftEdit/:id',
                                name: 'Post Draft Edit',
                                component: Point_Of_Sales,
                                meta: {
                                    icon: "fa fa-cart-arrow-down",
                                    breadcrumb: [
                                        { name: 'Post Draft Edit' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'POINT_OF_SALE',
                                    action: 'VIEW',
                                },
                            }
                        ],
                },
                {
                    path: '/',
                    component: AppLayout,
                    children:
                        [
                            {
                                path: '/',
                                name: 'Home',
                                component: Dashboard,
                                meta: {
                                    icon: 'mdi-view-dashboard',
                                    breadcrumb: [
                                        { name: 'Home' }
                                    ],
                                    allowAnonymous: false,
                                }
                            },
                            {
                                path: '/product',
                                name: 'Product',
                                component: Product,
                                meta: {
                                    icon: "store",
                                    breadcrumb: [
                                        { name: 'Product' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'PRODUCT_MENU',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'list',
                                        name: 'ListProduct',
                                        component: Product_List,
                                        meta: {
                                            icon: "store",
                                            breadcrumb: [
                                                { name: 'List Product' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'view/:id',
                                        name: 'ViewProduct',
                                        component: Product_View,
                                        meta: {
                                            icon: "store",
                                            breadcrumb: [
                                                { name: 'View Product' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'createEdit/:id',
                                        name: 'CreateEditProduct',
                                        component: Create_Edit_Product,
                                        meta: {
                                            icon: "store",
                                            breadcrumb: [
                                                { name: 'Create Edit Product' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT',
                                            action: ["CREATE", "UPDATE"],
                                        },
                                    },
                                    {
                                        path: 'productTags',
                                        name: 'ProductTags',
                                        component: Product_Tags,
                                        meta: {
                                            icon: "fas fa-spray-can",
                                            breadcrumb: [
                                                { name: 'Product Tags' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_TAGS',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'productAttribute',
                                        name: 'ProductAttribute',
                                        component: Product_Attribute,
                                        meta: {
                                            icon: "fa fa-indent",
                                            breadcrumb: [
                                                { name: 'Product Attribute' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_ATTRIBUTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'productAttributeOption',
                                        name: 'ProductAttributeOption',
                                        component: Product_Attribute_Option,
                                        meta: {
                                            icon: "fas fa-prescription-bottle",
                                            breadcrumb: [
                                                { name: 'Product Attribute Option' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_ATTRIBUTE_OPTION',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'productSupplier',
                                        name: 'ProductSupplier',
                                        component: Product_Supplier,
                                        meta: {
                                            icon: "fas fa-exchange-alt",
                                            breadcrumb: [
                                                { name: 'Product Supplier' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_SUPPLIER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'productUnits',
                                        name: 'ProductUnits',
                                        component: Product_Units,
                                        meta: {
                                            icon: "mdi-weight-kilogram",
                                            breadcrumb: [
                                                { name: 'Product Units' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_UNIT',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'productBatch',
                                        name: 'ProductBatch',
                                        component: Product_Batch,
                                        meta: {
                                            icon: "fa fa-object-group",
                                            breadcrumb: [
                                                { name: 'Product Batch' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_BATCH',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'productLocation',
                                        name: 'ProductLocation',
                                        component: Product_Location,
                                        meta: {
                                            icon: "fa fa-map-marker",
                                            breadcrumb: [
                                                { name: 'Product Location' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_LOCATION',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'productDailyPrice',
                                        name: 'productDailyPrice',
                                        component: Product_Daily_Price,
                                        meta: {
                                            icon: "fa fa-usd",
                                            breadcrumb: [
                                                { name: 'Product Daily Price' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_DAILY_PRICE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'uploads',
                                        name: 'Uploads',
                                        component: Uploads,
                                        meta: {
                                            icon: "fa-solid fa-boxes-packing",
                                            breadcrumb: [
                                                { name: 'Uploads' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'UPLOADS',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'tradeType',
                                        name: 'TradeType',
                                        component: Trade_Type,
                                        meta: {
                                            icon: "fa-solid fa-boxes-packing",
                                            breadcrumb: [
                                                { name: 'TradeType' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'TRADE_TYPE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'multipleSellingPrice',
                                        name: 'MultipleSellingPrice',
                                        component: Multiple_Selling_Price,
                                        meta: {
                                            icon: "fa-solid fa-boxes-packing",
                                            breadcrumb: [
                                                { name: 'MultipleSellingPrice' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'MULTIPLE_SELLING_PRICE',
                                            action: 'VIEW',
                                        },
                                    },
                                ],
                            },
                            {
                                path: '/Production',
                                name: 'Production',
                                component: Production,
                                meta: {
                                    icon: "fa-solid fa-boxes-packing",
                                    breadcrumb: [
                                        { name: 'Production' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'PRODUCTION',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'packaging',
                                        name: 'Packaging',
                                        component: Packaging,
                                        meta: {
                                            icon: "fa-solid fa-boxes-packing",
                                            breadcrumb: [
                                                { name: 'Packaging' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_PACKAGING',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'manufacturing',
                                        name: 'Manufacturing',
                                        component: Manufacturing,
                                        meta: {
                                            icon: "fa-solid fa-boxes-packing",
                                            breadcrumb: [
                                                { name: 'Manufacturing' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'MANUFACTURING',
                                            action: 'VIEW',
                                        },
                                    },
                                ]
                            },
                            {
                                path: '/purchase',
                                name: 'Purchase',
                                component: Purchase,
                                meta: {
                                    icon: "fa fa-shopping-cart",
                                    breadcrumb: [
                                        { name: 'Purchase' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'PURCHASE_MENU',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'supplier',
                                        name: 'Supplier',
                                        component: Supplier,
                                        meta: {
                                            icon: "fa fa-solid fa-truck-field",
                                            breadcrumb: [
                                                { name: 'Supplier' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUPPLIER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'purchaseOrder',
                                        name: 'PurchaseOrder',
                                        component: Purchase_Order_List,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'Purchase Order' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_ORDER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'purchaseOrder/view/:id',
                                        name: 'ViewPurchaseOrder',
                                        component: Purchase_Order_View,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'View Purchase Order' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_ORDER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'purchaseOrder/createEdit/:id',
                                        name: 'Create Edit Purchase Order',
                                        component: Purchase_Order_Create_Edit,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'Create Edit Purchase Order' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_ORDER',
                                            action: ['CREATE', "UPDATE"],
                                        },
                                    },
                                    {
                                        path: 'purchaseBill',
                                        name: 'PurchaseBill',
                                        component: Purchase_Bill_List,
                                        meta: {
                                            icon: "fas fa-file-invoice-dollar",
                                            breadcrumb: [
                                                { name: 'Purchase Bill' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_BILL',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'purchaseBill/view/:id',
                                        name: 'ViewPurchaseBill',
                                        component: Purchase_Bill_View,
                                        meta: {
                                            icon: "fas fa-file-invoice-dollar",
                                            breadcrumb: [
                                                { name: 'View Purchase Bill' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_BILL',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'purchaseBill/createEdit',
                                        name: 'Create Edit Purchase Bill',
                                        component: Purchase_Bill_Create_Edit,
                                        meta: {
                                            icon: "fas fa-file-invoice-dollar",
                                            breadcrumb: [
                                                { name: 'Create Edit Purchase Bill' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_BILL',
                                            action: ['CREATE', "UPDATE"]
                                        },
                                    },
                                    {
                                        path: 'debitNote',
                                        name: 'DebitNote',
                                        component: Debit_Note_List,
                                        meta: {
                                            icon: "fas fa-file-invoice-dollar",
                                            breadcrumb: [
                                                { name: 'Debit Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DEBIT_NOTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'debitNote/view/:id',
                                        name: 'ViewDebitNote',
                                        component: Debit_Note_View,
                                        meta: {
                                            icon: "fas fa-undo",
                                            breadcrumb: [
                                                { name: 'View Debit Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DEBIT_NOTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'debitNote/createEdit',
                                        name: 'Create Edit Debit Note',
                                        component: Debit_Note_Create_Edit,
                                        meta: {
                                            icon: "fas fa-undo",
                                            breadcrumb: [
                                                { name: 'Create Edit Debit Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DEBIT_NOTE',
                                            action: ['CREATE', "UPDATE"]
                                        },
                                    },
                                    {
                                        path: 'goodReceivedNote',
                                        name: 'GoodReceivedNoteList',
                                        component: Good_Received_Note_List,
                                        meta: {
                                            icon: "fas fa-shopping-bag",
                                            breadcrumb: [
                                                { name: 'Good Received Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'GOOD_RECEIVED_NOTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'goodReceivedNote/view/:id',
                                        name: 'ViewGoodReceivedNote',
                                        component: Good_Received_Note_View,
                                        meta: {
                                            icon: "fas fa-shopping-bag",
                                            breadcrumb: [
                                                { name: 'View Good Received Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'GOOD_RECEIVED_NOTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'goodReceivedNote/createEdit/:id',
                                        name: 'Create Edit Good Received Note',
                                        component: Good_Received_Note_Create_Edit,
                                        meta: {
                                            icon: "fas fa-file-invoice-dollar",
                                            breadcrumb: [
                                                { name: 'Create Edit Good Received Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'GOOD_RECEIVED_NOTE',
                                            action: ['CREATE', "EDIT"]
                                        },
                                    },
                                ],
                            },
                            {
                                path: '/sale',
                                name: 'Sale',
                                component: Sales,
                                meta: {
                                    icon: "fa fa-shopping-cart",
                                    breadcrumb: [{ name: 'Sale' }],
                                    allowAnonymous: false,
                                    resource: 'SALES_MENU',
                                    action: 'VIEW',

                                },
                                children: [
                                    {
                                        path: 'customer',
                                        name: 'Customer',
                                        component: Customer,
                                        meta: {
                                            icon: "fa fa-solid fa-user-tie",
                                            breadcrumb: [
                                                { name: 'Customer' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CUSTOMER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'salesOrder',
                                        name: 'SalesOrder',
                                        component: Sales_Order_List,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'Sales Order' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_ORDER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'salesOrder/createEdit/:id',
                                        name: 'Create Edit Sales Order',
                                        component: Sales_Order_Create_Edit,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'Create Edit Sales Order' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_ORDER',
                                            action: ['CREATE', 'UPDATE'],
                                        },
                                    },
                                    {
                                        path: 'salesOrder/view/:id',
                                        name: 'ViewSalesOrder',
                                        component: Sales_Order_View,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'View Sales Order' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_ORDER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'deliveryNote',
                                        name: 'DeliveryNote',
                                        component: Delivery_Note_List,
                                        meta: {
                                            icon: "fas fa-truck",
                                            breadcrumb: [
                                                { name: 'Delivery Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DELIVERY_NOTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'deliveryNote/createEdit/:id',
                                        name: 'Create Edit Delivery Note',
                                        component: Delivery_Note_Create_Edit,
                                        meta: {
                                            icon: "fas fa-truck",
                                            breadcrumb: [
                                                { name: 'Create Edit Delivery Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DELIVERY_NOTE',
                                            action: ['CREATE', 'UPDATE']
                                        },
                                    },
                                    {
                                        path: 'deliveryNote/view/:id',
                                        name: 'ViewDeliveryNote',
                                        component: Delivery_Note_View,
                                        meta: {
                                            icon: "fas fa-truck",
                                            breadcrumb: [
                                                { name: 'View Delivery Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DELIVERY_NOTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'salesInvoiceDeliveryNote/createEdit/:id?',
                                        name: 'Create Edit Conversion Delivery Note To Sales Invoice',
                                        component: Sales_Invoice_Conversion_Delivery_Note,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'Create Edit Conversion Delivery Note To Sales Invoice' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_INVOICE',
                                            action: 'CREATE',
                                        },
                                    },
                                    {
                                        path: 'salesInvoice',
                                        name: 'SalesInvoice',
                                        component: Sales_Invoice_List,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'Sales Invoice' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_INVOICE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'salesInvoice/view/:id',
                                        name: 'ViewSalesInvoice',
                                        component: Sales_Invoice_View,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'View Sales Invoice' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_INVOICE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'salesInvoice/createEdit/:id?',
                                        name: 'Create Edit Sales Invoice',
                                        component: Sales_Invoice_Create_Edit,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'Create Edit Sales Invoice' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_INVOICE',
                                            action: ['CREATE', 'UPDATE']
                                        },
                                    },
                                    {
                                        path: 'creditNote',
                                        name: 'CreditNote',
                                        component: Credit_Note_List,
                                        meta: {
                                            icon: "fa fa-cart-arrow-down",
                                            breadcrumb: [
                                                { name: 'Credit Note | Sales Return' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CREDIT_NOTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'creditNote/view/:id',
                                        name: 'ViewCreditNote',
                                        component: Credit_Note_View,
                                        meta: {
                                            icon: "fas fa-undo",
                                            breadcrumb: [
                                                { name: 'View Credit Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CREDIT_NOTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'creditNote/createEdit',
                                        name: 'Create Edit Credit Note',
                                        component: Credit_Note_Create_Edit,
                                        meta: {
                                            icon: "fa fa-undo",
                                            breadcrumb: [
                                                { name: 'Create Edit Credit Note' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CREDIT_NOTE',
                                            action: 'CREATE',
                                        },
                                    },
                                ],
                            },
                            {
                                path: '/account',
                                name: 'Account',
                                component: Account,
                                meta: {
                                    icon: "fas fa-book",
                                    breadcrumb: [
                                        { name: 'Account' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'ACCOUNT_MENU',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'voucher',
                                        name: 'Voucher',
                                        component: Voucher_List,
                                        meta: {
                                            icon: "fas fa-file-invoice",
                                            breadcrumb: [
                                                { name: 'Voucher' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'VOUCHER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'voucher/view/:id',
                                        name: 'ViewVoucherDetails',
                                        component: Voucher_View,
                                        meta: {
                                            icon: "fas fa-file-invoice",
                                            breadcrumb: [
                                                { name: 'View Voucher Details' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'VOUCHER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'stock',
                                        name: 'Stock',
                                        component: Stock_List,
                                        meta: {
                                            icon: "fas fa-layer-group",
                                            breadcrumb: [
                                                { name: 'Stock' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'otherBill',
                                        name: 'OtherBill',
                                        component: Other_Bill,
                                        meta: {
                                            icon: "far fa-money-bill-alt",
                                            breadcrumb: [
                                                { name: 'OtherBill' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'OTHER_BILL',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'otherBill/view/:id',
                                        name: 'ViewOtherBill',
                                        component: Other_Bill_View,
                                        meta: {
                                            icon: "fas fa-file-invoice-dollar",
                                            breadcrumb: [
                                                { name: 'View Other Bill' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'OTHER_BILL',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'cashManagement',
                                        name: 'CashManagement',
                                        component: Cash_Management_List,
                                        meta: {
                                            icon: "far fa-money-bill-alt",
                                            breadcrumb: [
                                                { name: 'Cash Management' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CASH_TRANSACTION',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'weightedAveragePrice',
                                        name: 'WeightedAveragePrice',
                                        component: Weighted_Average_Price_List,
                                        meta: {
                                            icon: "fas fa-layer-group",
                                            breadcrumb: [
                                                { name: 'WeightedAveragePrice' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'WEIGHTED_AVERAGE_PRICE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'stockTransfer',
                                        name: 'StockTransfer',
                                        component: Stock_Transfer_List,
                                        meta: {
                                            icon: "fas fa-exchange-alt",
                                            breadcrumb: [
                                                { name: 'Stock Transfer' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_TRANSFER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'stockTransfer/view/:id',
                                        name: 'ViewStockTransfer',
                                        component: Stock_Transfer_View,
                                        meta: {
                                            icon: "fas fa-exchange-alt",
                                            breadcrumb: [
                                                { name: 'View Stock Transfer' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_TRANSFER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'stockLocationTransfer',
                                        name: 'StockLocationTransfer',
                                        component: Stock_Location_Transfer_List,
                                        meta: {
                                            icon: "fas fa-exchange-alt",
                                            breadcrumb: [
                                                { name: 'Stock Location Transfer' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'LOCATION_TRANSFER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'stockLocationTransfer/view/:id',
                                        name: 'ViewStockLocationTransfer',
                                        component: Stock_Location_Transfer_View,
                                        meta: {
                                            icon: "fas fa-exchange-alt",
                                            breadcrumb: [
                                                { name: 'View Stock Location Transfer' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_TRANSFER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'stockAdjustment',
                                        name: 'StockAdjustment',
                                        component: Stock_Adjustment,
                                        meta: {
                                            icon: "fas fa-exchange-alt",
                                            breadcrumb: [
                                                { name: 'Stock Adjustment' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_ADJUSTMENT',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'stockAdjustment/view/:id',
                                        name: 'ViewStockAdjustment',
                                        component: Stock_Adjustment_View,
                                        meta: {
                                            icon: "fas fa-exchange-alt",
                                            breadcrumb: [
                                                { name: 'View Stock Adjustment' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_ADJUSTMENT',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'bank',
                                        name: 'Bank',
                                        component: Bank,
                                        meta: {
                                            icon: "fa fa-bank",
                                            breadcrumb: [
                                                { name: 'Bank' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'BANK',
                                            action: 'VIEW',

                                        },
                                    },
                                    {
                                        path: 'specialVoucher',
                                        name: 'Special Voucher',
                                        component: Special_Voucher_List,
                                        meta: {
                                            icon: "fas fa-file-invoice",
                                            breadcrumb: [
                                                { name: 'Special Voucher' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SPECIAL_VOUCHER',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'fiscalYearClosing',
                                        name: 'Fiscal Year Closing',
                                        component: Fiscal_Year_Closing,
                                        meta: {
                                            icon: "fas fa-file-invoice",
                                            breadcrumb: [
                                                { name: 'Fiscal Year Closing' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'FISCAL_YEAR_CLOSING',
                                            action: 'VIEW',
                                        },
                                    },
                                ],
                            },
                            {
                                path: '/units',
                                name: "Units",
                                component: Unit,
                                meta: {
                                    icon: "mdi-weight-kilogram",
                                    breadcrumb: [
                                        { name: 'Unit' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'UNIT',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'unitGroup',
                                        name: 'UnitGroup',
                                        component: Unit_Group_List,
                                        meta: {
                                            icon: "mdi-more",
                                            breadcrumb: [
                                                { name: 'Unit Group' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'UNIT_GROUP',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'unit',
                                        name: 'Unit',
                                        component: Unit_List,
                                        meta: {
                                            icon: "mdi-weight-kilogram",
                                            breadcrumb: [
                                                { name: 'Unit' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'UNIT',
                                            action: 'VIEW',
                                        }
                                    },
                                ],
                            },
                            {
                                path: '/configuration',
                                name: "Configuration",
                                component: Configuration,
                                meta: {
                                    icon: "fa fa-cogs",
                                    breadcrumb: [
                                        { name: 'Configuration' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'CONFIGURATION_VIEW',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'brand',
                                        name: 'Brand',
                                        component: Brand_List,
                                        meta: {
                                            icon: "fab fa-bandcamp",
                                            breadcrumb: [
                                                { name: 'Brand' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'BRAND',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'category',
                                        name: 'Category',
                                        component: Category_List,
                                        meta: {
                                            icon: "fa fa-layer-group",
                                            breadcrumb: [
                                                { name: 'Category' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CATEGORY',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'tags',
                                        name: 'Tags',
                                        component: Tags_List,
                                        meta: {
                                            icon: "fas fa-tag",
                                            breadcrumb: [
                                                { name: 'Tags' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'TAGS',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'attribute',
                                        name: 'Attribute',
                                        component: Attribute_List,
                                        meta: {
                                            icon: "fa fa-align-center",
                                            breadcrumb: [
                                                { name: 'Attribute' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ATTRIBUTE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'attributeOption',
                                        name: 'Attribute Option',
                                        component: Attribute_Option_List,
                                        meta: {
                                            icon: "fa fa-filter",
                                            breadcrumb: [
                                                { name: 'Attribute Option' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ATTRIBUTE_OPTION',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'accountHead',
                                        name: 'AccountHead',
                                        component: Account_Head_List,
                                        meta: {
                                            icon: "fas fa-book",
                                            breadcrumb: [
                                                { name: 'AccountHead' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ACCOUNT_HEAD',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'managedAccountHead',
                                        name: 'ManagedAccountHead',
                                        component: Managed_Account_Head_List,
                                        meta: {
                                            icon: "fas fa-book-reader",
                                            breadcrumb: [
                                                { name: 'ManagedAccountHead' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'MANAGED_ACCOUNT_HEAD',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'cashFlowConfig',
                                        name: 'CashFlowConfig',
                                        component: Cash_Flow_Config_List,
                                        meta: {
                                            icon: "fas fa-cash-register",
                                            breadcrumb: [
                                                { name: 'CashFlowConfig' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CASH_FLOW_CONFIG',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'manufacture',
                                        name: 'Manufacture',
                                        component: Manufacture_List,
                                        meta: {
                                            icon: "fab fa-bandcamp",
                                            breadcrumb: [
                                                { name: 'Manufacture' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'MANUFACTURE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'productType',
                                        name: 'ProductType',
                                        component: Product_Type_List,
                                        meta: {
                                            icon: "fab fa-bandcamp",
                                            breadcrumb: [
                                                { name: 'ProductType' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PRODUCT_TYPE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'voucherTemplate',
                                        name: 'VoucherTemplate',
                                        component: Voucher_Template_List,
                                        meta: {
                                            icon: "fab fa-bandcamp",
                                            breadcrumb: [
                                                { name: 'Voucher Template' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'VOUCHER_TEMPLATE',
                                            action: 'VIEW',
                                        },
                                    },
                                    {
                                        path: 'costCenter',
                                        name: 'costCenter',
                                        component: Cost_Center,
                                        meta: {
                                            icon: "fab fa-register",
                                            breadcrumb: [
                                                { name: 'Cost Center' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'COST_CENTER',
                                            action: 'VIEW',
                                        },
                                    },
                                ],
                            },

                            {
                                path: '/general',
                                name: "General",
                                component: General,
                                meta: {
                                    icon: "fa fa-wrench",
                                    breadcrumb: [
                                        { name: 'General' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'GENERAL_MENU',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'organization',
                                        name: 'Organization',
                                        component: Organization_List,
                                        meta: {
                                            icon: "fa fa-building",
                                            breadcrumb: [
                                                { name: 'Organization' }
                                            ],
                                            allowAnonymous: false,
                                            onlySuperUser: true,
                                            resource: 'ORGANIZATION',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'termsConditions',
                                        name: 'Terms and Conditions',
                                        component: Terms_Conditions,
                                        meta: {
                                            icon: "fa-solid fa-file-contract",
                                            breadcrumb: [
                                                { name: 'Terms and Conditions' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'TERMS_AND_CONDITION',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'branch',
                                        name: 'Branch',
                                        component: Branch_List,
                                        meta: {
                                            icon: "fa-solid fa-building-columns",
                                            breadcrumb: [
                                                { name: 'Branch' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'BRANCH',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'globalSettings',
                                        name: 'GlobalSettings',
                                        component: Global_Settings_List,
                                        meta: {
                                            icon: "fa fa-wrench",
                                            breadcrumb: [
                                                { name: 'Global Settings' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'GLOBAL_SETTINGS',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'branchGlobalSettings/:globalSettingsId',
                                        name: 'BranchGlobalSettings',
                                        component: Branch_Global_settings_List,
                                        meta: {
                                            icon: "fa fa-wrench",
                                            breadcrumb: [
                                                { name: 'Branch Global Settings' }
                                            ],
                                            allowAnonymous: false
                                        }
                                    },
                                    {
                                        path: 'baseOrderNumber',
                                        name: 'Base Order Number',
                                        component: Base_Order_Number_List,
                                        meta: {
                                            icon: "fa fa-play",
                                            breadcrumb: [
                                                { name: 'Base Order Number' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'BASE_ORDER_NUMBER',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'location',
                                        name: 'Location',
                                        component: Location_List,
                                        meta: {
                                            icon: "fa fa-map-marker",
                                            breadcrumb: [
                                                { name: 'Location' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'LOCATION',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'scheme',
                                        name: 'Scheme',
                                        component: Scheme_List,
                                        meta: {
                                            icon: "fa fa-gift",
                                            breadcrumb: [
                                                { name: 'Scheme' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SCHEME',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'giftConfig',
                                        name: 'GiftConfig',
                                        component: Gift_Config_List,
                                        meta: {
                                            icon: "fa fa-gift",
                                            breadcrumb: [
                                                { name: 'Gift Config' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'GIFT_CONFIG',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'customerCategory',
                                        name: 'CustomerCategory',
                                        component: Customer_Category_List,
                                        meta: {
                                            icon: "fa fa-paw",
                                            breadcrumb: [
                                                { name: 'Customer Category' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CUSTOMER_CATEGORY',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'loyaltyPoint',
                                        name: 'LoyaltyPoint',
                                        component: Loyalty_Point_List,
                                        meta: {
                                            icon: "fa fa-cubes  ",
                                            breadcrumb: [
                                                { name: 'Loyalty Point' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'LOYALTY_POINT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'customerLoyaltyPoint',
                                        name: 'CustomerLoyaltyPoint',
                                        component: Customer_Loyalty_Point_List,
                                        meta: {
                                            icon: "fa fa-plus-square",
                                            breadcrumb: [
                                                { name: 'CustomerLoyaltyPoint' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CUSTOMER_LOYALTY_POINT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'forecastConfig',
                                        name: 'ForecastConfig',
                                        component: Forecast_Config_List,
                                        meta: {
                                            icon: "fa fa-fire",
                                            breadcrumb: [
                                                { name: 'ForecastConfig' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'FORECAST',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'overheadExpenses',
                                        name: 'OverheadExpenses',
                                        component: Overhead_Expenses,
                                        meta: {
                                            icon: "pi pi-money-bill",
                                            breadcrumb: [
                                                { name: 'OverheadExpenses' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'OVERHEAD_EXPENSES',
                                            action: 'VIEW',
                                        }
                                    },
                                ]
                            },
                            {
                                path: '/roles',
                                name: "Roles",
                                component: Role,
                                meta: {
                                    icon: "fa fa-universal-access",
                                    breadcrumb: [
                                        { name: 'Roles' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'ROLE',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'role',
                                        name: 'Role',
                                        component: Role_List,
                                        meta: {
                                            icon: "fa fa-key",
                                            breadcrumb: [
                                                { name: 'Role' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ROLE',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'resources',
                                        name: 'Resources',
                                        component: Resource_List,
                                        meta: {
                                            icon: "fa fa-object-group",
                                            breadcrumb: [
                                                { name: 'Resources' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'RESOURCES',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'action',
                                        name: 'Action',
                                        component: Action_List,
                                        meta: {
                                            icon: "fa fa-object-group",
                                            breadcrumb: [
                                                { name: 'Action' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ACTION',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'resourceAction',
                                        name: 'ResourceAction',
                                        component: Resource_Action_List,
                                        meta: {
                                            icon: "fa fa-object-group",
                                            breadcrumb: [
                                                { name: 'ResourceAction' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'RESOURCE_ACTION',
                                            action: 'VIEW',
                                        }
                                    },
                                ],
                            },
                            {
                                path: '/users',
                                name: "Users",
                                component: User,
                                meta: {
                                    icon: "fa fa-users",
                                    breadcrumb: [
                                        { name: 'Users' }
                                    ],
                                    allowAnonymous: false,
                                },
                                children: [
                                    {
                                        path: 'user',
                                        name: 'User',
                                        component: User_List,
                                        meta: {
                                            icon: "fa-solid fa-people-group",
                                            breadcrumb: [
                                                { name: 'User' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'USER',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'userDevice',
                                        name: 'User Device',
                                        component: User_Device,
                                        meta: {
                                            icon: "fa-solid fa-computer",
                                            breadcrumb: [
                                                { name: 'User' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'USER_DEVICE',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'organization/user',
                                        name: 'Organization User',
                                        component: Organization_User_List,
                                        meta: {
                                            icon: "fa fa-user",
                                            breadcrumb: [
                                                { name: 'Organization User' }
                                            ],
                                            allowAnonymous: false,
                                        }
                                    },
                                    {
                                        path: 'UserOrganization/:userId',
                                        name: 'userOrganization',
                                        component: User_Organization_List,
                                        meta: {
                                            icon: "fa fa-user-plus",
                                            breadcrumb: [
                                                { name: 'UserOrganization' }
                                            ],
                                            allowAnonymous: false
                                        }
                                    },
                                    {
                                        path: 'userActivity',
                                        name: 'User Activity',
                                        component: User_Activity,
                                        meta: {
                                            icon: "fa fa-solid fa-clipboard-list",
                                            breadcrumb: [
                                                { name: 'User Activity' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'USER_ACTIVITY',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'switchOrganization',
                                        name: 'Switch Organization',
                                        component: Switch_Organization,
                                        meta: {
                                            icon: "fa fa-solid fa-clipboard-list",
                                            breadcrumb: [
                                                { name: 'Switch Organization' }
                                            ],
                                            allowAnonymous: false
                                        }
                                    },
                                ],
                            },

                            {
                                path: '/notification',
                                name: "Notification",
                                component: Notification,
                                meta: {
                                    icon: "fa fa-bell",
                                    breadcrumb: [
                                        { name: 'Notification' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'NOTIFICATION_MENU',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'notificationSettings',
                                        name: 'Notification Settings',
                                        component: Notification_Settings,
                                        meta: {
                                            icon: "fa fa-wrench",
                                            breadcrumb: [
                                                { name: 'Notification Settings' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'NOTIFICATION_SETTINGS',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'notificationMessage',
                                        name: 'Notification Message',
                                        component: Notification_Message,
                                        meta: {
                                            icon: "fas fa-file-word",
                                            breadcrumb: [
                                                { name: 'Notification Message' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'NOTIFICATION_MESSAGE',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'notificationUser',
                                        name: 'Notification User',
                                        component: Notification_User,
                                        meta: {
                                            icon: "fa fa-history",
                                            breadcrumb: [
                                                { name: 'Notification User' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'NOTIFICATION_USER',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'socketSessionUser',
                                        name: 'Socket Session User',
                                        component: Socket_Session_User,
                                        meta: {
                                            icon: "fa fa-user-secret",
                                            breadcrumb: [
                                                { name: 'Socket Session User' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SOCKET_SESSION_USER',
                                            action: 'VIEW',
                                        }
                                    },

                                ],
                            },
                            {
                                path: '/reports',
                                name: "Reports",
                                component: Reports,
                                meta: {
                                    icon: "fas fa-book-atlas",
                                    breadcrumb: [
                                        { name: 'Reports' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'REPORT_MENU',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'vatSalesReport',
                                        name: 'Vat Sales Report',
                                        component: Vat_Sales_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Vat Sales Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'VAT_SALES_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                        {
                                            path: 'vatPurchaseReport',
                                            name: 'Vat Purchase Report',
                                            component: Vat_Purchase_Report,
                                            meta: {
                                                icon: "fas fa-swatchbook",
                                                breadcrumb: [
                                                    {name: 'Vat Purchase Report'}
                                                ],
                                                allowAnonymous: false,
                                                resource: 'VAT_PURCHASE_REPORT',
                                                action: 'VIEW',
                                            }
                                        },
                                    {
                                        path: 'salesBookReport',
                                        name: 'Sales Book Report',
                                        component: Sales_Book_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Sales Book Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_BOOK_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'salesRegisterReport',
                                        name: 'Sales Register Report',
                                        component: Sales_Register_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Sales Register Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SALES_REGISTER_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'purchaseBookReport',
                                        name: 'Purchase Book Report',
                                        component: Purchase_Book_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Purchase Book Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_BOOK_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'purchaseRegisterReport',
                                        name: 'Purchase Register Report',
                                        component: Purchase_Register_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Purchase Register Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_REGISTER_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'creditNoteReport',
                                        name: 'Credit Note Report',
                                        component: Credit_Note_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Credit Note Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CREDIT_NOTE_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'debitNoteReport',
                                        name: 'Debit Note Report',
                                        component: Debit_Note_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Debit Note Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DEBIT_NOTE_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'stockReport',
                                        name: 'Stock Report',
                                        component: Stock_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Stock Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'openingStockReport',
                                        name: 'Opening Stock Report',
                                        component: Opening_Stock_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Opening Stock Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'OPENING_STOCK_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'balanceSheetReport',
                                        name: 'Balance Sheet Report',
                                        component: Balance_Sheet_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Balance Sheet Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'BALANCE_SHEET_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'trialBalanceReport',
                                        name: 'Trial Balance Report',
                                        component: Trial_Balance_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Trial Balance Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'TRIAL_BALANCE_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'incomeStatementReport',
                                        name: 'Income Statement Report',
                                        component: Income_Statement_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Income Statement Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'INCOME_STATEMENT_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'customerTransactionReport',
                                        name: 'Customer Transaction Report',
                                        component: Customer_Transaction_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Customer Transaction Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CUSTOMER_TRANSACTION_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'supplierTransactionReport',
                                        name: 'Supplier Transaction Report',
                                        component: Supplier_Transaction_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Supplier Transaction Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUPPLIER_TRANSACTION_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'supplierTransactionReportById/:id',
                                        name: 'Supplier Transaction Report By Id',
                                        component: Supplier_Transaction_By_Id_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Supplier Transaction Report By Id' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUPPLIER_TRANSACTION_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'customerTransactionReportById/:id',
                                        name: 'Customer Transaction Report By Id',
                                        component: Customer_Transaction_By_Id_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Customer Transaction Report By Id' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CUSTOMER_TRANSACTION_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'subLedgerReport',
                                        name: 'Sub Ledger Report',
                                        component: Sub_Ledger_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Sub Ledger Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUB_LEDGER_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'stockSummaryReport',
                                        name: 'Stock Summary Report',
                                        component: Stock_Summary_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Stock Summary Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_SUMMARY_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'stockSummaryReportById/:id',
                                        name: 'Stock Summary Report By Id',
                                        component: Stock_Summary_Report_By_Id,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Stock Summary Report By Id' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_SUMMARY_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                        {
                                            path: 'safetyStockReport',
                                            name: 'Safety Stock Report',
                                            component: Safety_Stock_Report,
                                            meta: {
                                                icon: "fa fa-book-atlas",
                                                breadcrumb: [
                                                    {name: 'Safety Stock Report'}
                                                ],
                                                allowAnonymous: false,
                                                resource: 'SAFETY_STOCK_REPORT',
                                                action: 'VIEW',
                                            }
                                        },
                                        {
                                            path: 'expiryReport',
                                            name: 'Expiry Report',
                                            component: Expiry_Report,
                                            meta: {
                                                icon: "fa fa-book-atlas",
                                                breadcrumb: [
                                                    {name: 'Expiry Report'}
                                                ],
                                                allowAnonymous: false,
                                                resource: 'EXPIRY_REPORT',
                                                action: 'VIEW',
                                            }
                                        },
                                        {
                                            path: 'reorderReport',
                                            name: 'Reorder Report',
                                            component: Reorder_Report,
                                            meta: {
                                                icon: "fa fa-book-atlas",
                                                breadcrumb: [
                                                    {name: 'Reorder Report'}
                                                ],
                                                allowAnonymous: false,
                                                resource: 'REORDER_REPORT',
                                                action: 'VIEW',
                                            }
                                        },
                                    {
                                        path: 'itemWiseSalesOrderReport',
                                        name: 'Item Wise Sales Order',
                                        component: Item_Wise_Sales_Order,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                {name: 'Item Wise Sales Order Report'}
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ITEM_WISE_SALES_ORDER_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'itemWiseSalesInvoiceReport',
                                        name: 'Item Wise Sales Invoice Report',
                                        component: Item_Wise_Sales_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                {name: 'Item Wise Sales Invoice Report'}
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ITEM_WISE_SALES_INVOICE_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'dayBookReport',
                                        name: 'Day Book Report',
                                        component: View_Day_Book_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Day book Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DAY_BOOK_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'customerBalanceConfirmationReport',
                                        name: 'Customer Balance Confirmation Report',
                                        component: Customer_Balance_Confirmation_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Customer Confirmation Balance Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CUSTOMER_BALANCE_CONFIRMATION_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'supplierBalanceConfirmationReport',
                                        name: 'Supplier Balance Confirmation Report',
                                        component: Supplier_Balance_Confirmation_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Supplier Confirmation Balance Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUPPLIER_BALANCE_CONFIRMATION_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'cashAndBankReport',
                                        name: 'Cash and Bank Report',
                                        component: View_Cash_and_Bank_Report,
                                        meta: {
                                            icon: "fas fa-swatchbook",
                                            breadcrumb: [
                                                { name: 'Cash and Bank Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CASH_AND_BANK_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'productPurchaseTransactionReport',
                                        name: 'Product Purchase Transaction Report',
                                        component: Product_Purchase_Transaction_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Product Purchase Transaction Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'PURCHASE_BILL_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'productSalesTransactionReport',
                                        name: 'Product Sales Transaction Report',
                                        component: Product_Sales_Transaction_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Product Sales Transaction Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'DAILY_SALES_REPORT',
                                            action: 'VIEW',
                                        }
                                    }, {
                                        path: 'productStockReport',
                                        name: 'Product Stock Report',
                                        component: Product_Stock_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Product Stock Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'STOCK_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'cashAndBankTransactionReport',
                                        name: 'Cash And Bank Transaction Report',
                                        component: Cash_And_Bank_Report_Transaction_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Cash And Bank Transaction Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CASHIER_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'bankTransactionReport',
                                        name: 'Bank Transaction Report',
                                        component: Bank_Report_Transaction_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Bank Transaction Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CASHIER_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'cashTransactionReport',
                                        name: 'Cash Transaction Report',
                                        component: Cash_Report_Transaction_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Cash Transaction Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CASHIER_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'customerAgeingReport',
                                        name: 'Customer Ageing Report',
                                        component: Customer_Ageing_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Customer Ageing Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'CUSTOMER_AGEING_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'supplierAgeingReport',
                                        name: 'Supplier Ageing Report',
                                        component: Supplier_Ageing_Report,
                                        meta: {
                                            icon: "fa fa-book-atlas",
                                            breadcrumb: [
                                                { name: 'Supplier Ageing Report' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUPPLIER_AGEING_REPORT',
                                            action: 'VIEW',
                                        }
                                    },
                                ]
                            },
                            {
                                path: '/subscription',
                                name: "Subscription",
                                component: Subscription,
                                meta: {
                                    icon: "fa fa-bell",
                                    breadcrumb: [
                                        { name: 'Subscription Feature' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'SUBSCRIPTION_MENU',
                                    action: 'VIEW',
                                },
                                children: [
                                    {
                                        path: 'softwareFeature',
                                        name: 'Software Feature',
                                        component: Software_Feature,
                                        meta: {
                                            icon: "fa fa-wrench",
                                            breadcrumb: [
                                                { name: 'Software Feature' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SOFTWARE_FEATURE',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'subscriptionFeature',
                                        name: 'Subscription Feature',
                                        component: Subscription_Feature,
                                        meta: {
                                            icon: "fa fa-wrench",
                                            breadcrumb: [
                                                { name: 'Subscription Feature' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUBSCRIPTION_FEATURE',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'subscriptionPackage',
                                        name: 'Subscription Package',
                                        component: Subscription_Package,
                                        meta: {
                                            icon: "fa-solid fa-box",
                                            breadcrumb: [
                                                { name: 'Subscription Package' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUBSCRIPTION_PACKAGE',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'subscriptionValidity',
                                        name: 'Subscription Validity',
                                        component: Subscription_Validity,
                                        meta: {
                                            icon: "fa-solid fa-calendar-plus",
                                            breadcrumb: [
                                                { name: 'Subscription Feature' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'SUBSCRIPTION_VALIDITY',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'organizationSubscription',
                                        name: 'Organization Subscription',
                                        component: Organization_Subscription,
                                        meta: {
                                            icon: "fa-solid fa-calendar-plus",
                                            breadcrumb: [
                                                { name: 'Organization Subscription' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ORGANIZATION_SUBSCRIPTION',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'organization/view/:id',
                                        name: 'View Organization Subscription',
                                        component: View_Organization_Subscription,
                                        meta: {
                                            icon: "fas fa-eye",
                                            breadcrumb: [
                                                { name: 'View Organization Subscription' }
                                            ],
                                            allowAnonymous: false,
                                            resource: 'ORGANIZATION_SUBSCRIPTION',
                                            action: 'VIEW',
                                        }
                                    },
                                    {
                                        path: 'viewSubscriptionPackage',
                                        name: 'View Subscription Package',
                                        component: View_Subscription_Package,
                                        meta: {
                                            icon: "fas fa-eye",
                                            breadcrumb: [
                                                { name: 'View Subscription Package' }
                                            ],
                                            allowAnonymous: false
                                        }
                                    },
                                ],
                            },

                            {
                                path: '/admin',
                                name: 'Super Admin Home',
                                component: Super_Admin_Dashboard,
                                meta: {
                                    icon: 'mdi-view-dashboard',
                                    breadcrumb: [
                                        { name: 'Super Admin Home' }
                                    ],
                                    allowAnonymous: false,
                                    resource: 'SUPER_ADMIN_DASHBOARD',
                                    action: 'VIEW',
                                }
                            },
                        ]
                }
            ]
    }
);

router.beforeResolve((to, from, next) => {
    const alertStore = useAlertStore();
    alertStore.clear();

    const authStore = useAuthStore();
    const isLoggedIn = authStore.isLoggedIn
        && authStore.user.username !== null && authStore.user.username !== undefined
        && authStore.org.code !== null && authStore.org.code !== undefined;
    const isSuperUser = authStore.superUser;

    if (isLoggedIn) {
        const socketStore = useSocketStore();
        if (socketStore.socket === undefined || socketStore.socket === null) {
            socketStore.reconnect();
        }
    }
    if(isLoggedIn && to.fullPath.includes("admin")){
        authStore.setSuperAdminDashboardClicked(true);
    }

    if ((to.matched.some(record => record.meta.allowAnonymous))
        || (to.matched.some(record => !record.meta.allowAnonymous) && isLoggedIn)) {
        // this route requires auth, check if logged in or allow anonymous if not, redirect to login page.
        if (isLoggedIn && to.matched.some(({ name }) => name === 'Login')) {
            //   return  router.push('/');
            return next({ name: 'Home' })
        } // Super User Check
        else if (isLoggedIn && !isSuperUser && to.matched.some(record => record.meta.onlySuperUser)) {
            alertStore.error("You are not authorized to access this page !");
            return next(from)
        }  //ACL Check
        else if (isLoggedIn && to.meta.resource !== undefined && to.meta.action !== undefined
            && !hasAcl(to.meta.resource, to.meta.action)) {
            alertStore.error("You are not authorized to access this page !");
            return next(from)
        } else {
            return next() // go to wherever I'm going
        }
    }
    authStore.returnUrl = to?.fullPath;
    return next({ name: 'Login' }) // redirect to Login page
})





